import gsap from "gsap";
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default function toggleCallback() {
  let button = $('.hero__icon');
  let form = $('.callback');
  let close = $('.callback__close');
  let bg = $('.hero__bg');
  let pulse = $('.hero__pulse');
  let timer = 0;
  let timer2 = 0;
  button.on('click', function() {
    pulse.fadeOut(200);
    $(this).fadeOut(200, function() {
      bg.fadeIn(0).addClass('active');
      disablePageScroll();
      timer = setTimeout(function() {
        gsap.to(form, .4, {opacity: 1, zIndex: 900});
      }, 500);
      timer2 = setTimeout(function() {
        bg.hide()
      }, 1000);
    });
  });
  function closeCallback() {
    clearTimeout(timer);
    bg.show();
    gsap.to(form, .4, {opacity: 0, zIndex: -1, onComplete: () => {
      bg.removeClass('active')
      enablePageScroll();
      setTimeout(function() {
        pulse.fadeIn(200);
        button.fadeIn()
        $('.callback form').show();
        $('.callback__title h2').text('Расскажите о вашем проекте');
      }, 700);
    }});
  }
  $(window).on('keydown', function(e) {
    if ( e.keyCode == 27 ) {
      closeCallback();
    }
  });
  close.on('click', function() {
    closeCallback();
  });
}
toggleCallback();